
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    bgColor: {
      type: String,
      default: "bg-white"
    },
    titleFontWeight: {
      type: String,
      default: "font-semibold"
    },
    defaultOpened: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    caption: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    customLabel: {
      type: Boolean,
      default: false
    },
    footer: {
      type: Boolean,
      default: false
    },
    borderHeader: {
      type: String,
      default: "border"
    },
    roundedHeader: {
      type: String,
      default: "rounded-t-md"
    },
    mainBorderEdge: {
      type: String,
      default: "border-r border-l border-b"
    },
    chevrons:{
      type: [],
      default: ["chevron-down","chevron-right"]
    },
    borderBody: {
      type: String,
      default: "border-gray-lp-400"
    },
    customWidthHeader: {
      type: String,
      default: "w-11/12"
    },
    modelValue: {
      required: true
    },
    customClass: {
      type: String,
      default: "px-6"
    }
  }
})
export default class Accordion extends Vue {
  mounted() {
    this.model = this.props.defaultOpened;
  }
  props: any = this.$props;
  model = false;

  get modelAccordion() {
    if (this.props.disabled) {
      return false;
    }
    return this.model;
  }

  get expandIcon() {
    return this.modelAccordion ? this.props.chevrons[0] : this.props.chevrons[1];
  }

  toggleAccordion() {
    if (this.props.disabled) {
      return;
    }
    this.model = !this.model;
    this.$emit('update:modelValue', this.model);
  }
}
