
import { Options, Vue } from "vue-class-component";
import { VendorController } from "@/app/ui/controllers/VendorController";
import {
  checkRolePermission,
  dateToDateString,
  dateToTime
} from "@/app/infrastructures/misc/Utils";
import { ROUTE_VENDOR } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {}
})
export default class DetailVendor extends Vue {
  async created() {
    this.fetchVendor();
  }

  get isEditAble() {
    return checkRolePermission(ROUTE_VENDOR.EDIT);
  }

  get detailVendor() {
    return VendorController.vendorDetail;
  }

  get isLoading() {
    return VendorController.isLoadingDetail;
  }

  formatDate(date: string) {
    return `${dateToDateString(new Date(date))}, ${dateToTime(
      new Date(date)
    )} WIB`;
  }

  getDetailVendorData(id: any) {
    return VendorController.getVendorDetail({ id });
  }

  fetchVendor() {
    this.getDetailVendorData(this.$route.params.id);
  }

  goBack() {
    this.$router.push("/network/vendor");
  }
  goEdit(id: string) {
    this.$router.push(`/network/vendor/${id}/edit`);
  }

  get isError() {
    return VendorController.isError;
  }

  get errorCause() {
    return VendorController.errorCause;
  }

  closeModalError() {
    VendorController.handleError();
  }
}
