
/* eslint-disable @typescript-eslint/camelcase */
import { Options, Vue } from "vue-class-component";
import Input from "@/app/ui/components/input/index.vue";

import {
  convertPhoneNumber,
  dateToDateString,
  dateToTime,
  formattedNPWP
} from "@/app/infrastructures/misc/Utils";
import { VendorController } from "@/app/ui/controllers/VendorController";

@Options({
  components: {
    Input
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      VendorController.setOpenModalSuccess(false);
      next();
    }
  }
})
export default class Edit extends Vue {
  async created() {
    this.fetchVendor();
  }

  updated() {
    this.removePrefix();
  }

  isDisabled = true;
  openRoute = false;
  phoneValue = 0;
  route = true;
  isEditTerminated = false;
  newPath = "";
  answer = false;
  validEmail = true;
  disabledButton = true;
  arrowDown = true;
  invalidStartDate = false;
  invalidEndDate = false;

  get isLoading() {
    return VendorController.isLoadingDetail;
  }

  getDetailVendorData(id: any) {
    return VendorController.getVendorDetail({ id });
  }

  fetchVendor() {
    this.getDetailVendorData(this.$route.params.id);
  }

  get detailVendor(): any {
    return VendorController.vendorDetail;
  }

  get contractStart(): any {
    return VendorController.convertContractStart;
  }

  get contractEnd(): any {
    return VendorController.convertContractEnd;
  }

  get messageSuccess() {
    return VendorController.messageSuccess;
  }

  validateEmail() {
    this.validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
      this.detailVendor.email
    );
  }

  removePrefix() {
    if (this.detailVendor.phone_number.startsWith("+62")) {
      this.detailVendor.phone_number = this.detailVendor.phone_number.replace(
        "+62",
        ""
      );
    }
  }

  validatePhoneNumber() {
    const regex = /[^0-9]+/g;
    this.detailVendor.phone_number = this.detailVendor.phone_number.replace(
      regex,
      ""
    );
  }

  inputDateStart(param: Date) {
    VendorController.setContractStart(param);
    const secondDate = new Date(this.contractEnd);
    this.invalidStartDate =
      param.getTime() > secondDate.getTime() ||
      param.getTime() === secondDate.getTime();
  }

  inputDateEnd(param: Date) {
    VendorController.setContractEnd(param);
    const secondDate = new Date(this.contractStart);
    this.invalidEndDate =
      param.getTime() < secondDate.getTime() ||
      param.getTime() === secondDate.getTime();
  }

  formatted() {
    this.detailVendor.tax_number = this.detailVendor.tax_number.replace(
      /[^0-9]+/g,
      ""
    );
    this.detailVendor.tax_number = formattedNPWP(this.detailVendor.tax_number);
  }

  isDisabledButton() {
    if (
      this.detailVendor.email !== "" &&
      this.detailVendor.address !== "" &&
      this.detailVendor.phone_number !== "" &&
      this.detailVendor.tax_number !== "" &&
      this.detailVendor.tax_number.length === 20 &&
      this.contractStart.toString() !== "" &&
      this.contractEnd.toString() !== "" &&
      this.validEmail &&
      !this.invalidStartDate &&
      !this.invalidEndDate
    ) {
      this.disabledButton = false;
    } else {
      return (this.disabledButton = true);
    }
  }

  onOpenRoute() {
    if (this.isRoute()) {
      this.openRoute = !this.openRoute;
    } else {
      this.openRoute = false;
      VendorController.setRoute(true);
    }
  }

  onCloseRoute() {
    this.openRoute = false;
  }

  changeRoute() {
    VendorController.setRoute(!this.isRoute());
    this.openRoute = false;
  }

  // Modal Success
  get openSuccess() {
    return VendorController.openModalSuccess;
  }
  onCloseSuccess() {
    this.goBack();
  }

  formatDate(date: string) {
    return `${dateToDateString(new Date(date))}, ${dateToTime(
      new Date(date)
    )} WIB`;
  }

  goBack() {
    this.$router.push(`/network/vendor/${this.$route.params.id}`);
  }

  isRoute() {
    return VendorController.isRoute;
  }

  openSave() {
    return VendorController.openModal;
  }

  openModalSave() {
    VendorController.setModalOpenSave(true);
  }

  closeModalSave() {
    VendorController.setModalOpenSave(false);
  }

  _onEdit() {
    VendorController.editVendor({
      vendor_id: Number(this.$route.params.id),
      address: this.detailVendor.address,
      email: this.detailVendor.email,
      status: this.isRoute() ? "active" : "inactive",
      phone_number: convertPhoneNumber(this.detailVendor.phone_number),
      tax_number: this.detailVendor.tax_number,
      contract_start: new Date(this.contractStart).toISOString(),
      contract_end: new Date(this.contractEnd).toISOString()
    });
  }

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }
}
