import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  "aria-describedby": "data-table-genesis",
  class: "w-full mt-8"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Accordion = _resolveComponent("Accordion")!
  const _directive_sanitize_html = _resolveDirective("sanitize-html")!

  return (_openBlock(), _createBlock("table", _hoisted_1, [
    _createVNode("thead", null, [
      _createVNode("tr", null, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.columns, (column, index) => {
          return (_openBlock(), _createBlock("th", {
            key: index,
            id: `${_ctx.replaceWhiteSpaces(column.name)}-${index}`,
            class: 
            `${
              column.styleHead
            } font-semibold bg-gray-lp-700 text-12px xxl:text-14px px-4 py-4 text-black-lp-200 ${index ===
              0 && 'rounded-l-lg'} ${index === _ctx.columns.length - 1 &&
              'rounded-r-lg'}`
          
          }, _toDisplayString(column.name), 11, ["id"]))
        }), 128))
      ])
    ]),
    (_ctx.data.length > 0)
      ? (_openBlock(), _createBlock("tbody", _hoisted_2, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
            return (_openBlock(), _createBlock(_Fragment, { key: index }, [
              _createVNode("tr", {
                onClick: ($event: any) => (
            _ctx.isDetailAble && !item.subData?.length ? _ctx.onClick(item) : () => {}
          ),
                class: ["hover:bg-gray-lp-100", {
            'cursor-pointer': _ctx.isDetailAble
          }]
              }, [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.columns, (column, indexColumn) => {
                  return (_openBlock(), _createBlock("td", {
                    key: indexColumn,
                    class: 
              `${column.styleBody} px-4 py-6 xl:py-6 text-12px xxl:text-14px ${
                !item.subData?.length ? 'border-b' : ''
              }  border-gray-lp-400`
            
                  }, [
                    _withDirectives(_createVNode("div", null, null, 512), [
                      [_directive_sanitize_html, column.render(item, index)]
                    ])
                  ], 2))
                }), 128))
              ], 10, ["onClick"]),
              (item.subData?.length)
                ? (_openBlock(), _createBlock("tr", _hoisted_3, [
                    _createVNode("td", {
                      colspan: _ctx.columns.length,
                      class: "border-b border-gray-lp-400 pl-16"
                    }, [
                      _createVNode(_component_Accordion, {
                        label: "Daftar Jenis Pengiriman",
                        class: "text-12px xxl:text-14px mb-5 bg-gray-lp-1000",
                        defaultOpened: false,
                        bgColor: "bg-gray-lp-1000",
                        titleFontWeight: "font-normal"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createBlock(_Fragment, null, _renderList(item.subData, (dataExpand, indexExpand) => {
                            return (_openBlock(), _createBlock("tr", {
                              key: indexExpand,
                              onClick: ($event: any) => (_ctx.isDetailAble ? _ctx.onClick(dataExpand) : () => {}),
                              class: {
                  'cursor-pointer': _ctx.isDetailAble
                }
                            }, [
                              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.columnsCollipsible, (column, indexColumn) => {
                                return (_openBlock(), _createBlock("td", {
                                  key: indexColumn,
                                  class: 
                    `${column.styleBody} px-4 py-6 xl:py-6 text-12px xxl:text-14px border-b border-gray-lp-400`
                  
                                }, [
                                  _withDirectives(_createVNode("div", null, null, 512), [
                                    [_directive_sanitize_html, column.render(dataExpand, indexExpand)]
                                  ])
                                ], 2))
                              }), 128))
                            ], 10, ["onClick"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1024)
                    ], 8, ["colspan"])
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}