
import { Options, Vue } from "vue-class-component";
import TableV1 from "@/app/ui/components/table/index.vue";
import { VendorController } from "@/app/ui/controllers/VendorController";
import debounce from "lodash/debounce";
import { checkRolePermission } from "@/app/infrastructures/misc/Utils";
import { ROUTE_VENDOR } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    TableV1
  }
})
export default class Vendor extends Vue {
  get getCurrentRoute() {
    return this.$route.meta.title;
  }
  get isShowTable() {
    return this.vendors.length > 0 && !this.isLoading && !this.isError;
  }
  get isDetailAble() {
    return checkRolePermission(ROUTE_VENDOR.DETAIL);
  }

  statusSelect = false;
  statusName = "";
  search = "";
  page = 1;

  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" }
  ];

  columns = [
    {
      name: "ID Vendor",
      key: "id",
      styleHead: "w-1/12 text-center whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: any) => {
        return `<span class='text-center text-red-lp-200 flex justify-center'>${item.id}</span>`;
      }
    },
    {
      name: "Nama Vendor",
      key: "Vendor Name",
      styleHead: "w-1/12 text-left",
      styleBody: "text-left rounded py-0",
      render: (item: any) => {
        return `<div class='flex flex-col'>${item.name}
        <p class="text-gray-lp-500 text-12px xxl:text-14px font-normal mt-1">${item.code}</p>
        </div>`;
      }
    },
    {
      name: "Alamat",
      key: "Alamat",
      styleHead: "w-1/6 text-left",
      styleBody: "text-left rounded py-0",
      render: (item: any) => {
        return `<div>${
          item.address.length > 60
            ? `${item.address.substr(0, 60)}...`
            : item.address
        }</div>`;
      }
    },
    {
      name: "Email",
      key: "Email",
      styleHead: "w-1/12 text-left",
      styleBody: "text-left rounded py-0",
      render: (item: any) => {
        return `<div>${item.email}</div>`;
      }
    },
    {
      name: "No. Telepon",
      styleHead: "w-1/12 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<span class="whitespace-no-wrap">
              ${item.phone_number !== "" ? item.phone_number : "-"}</span>`;
      }
    },
    {
      name: "Status Vendor",
      key: "status",
      styleHead: "w-1/12 text-left",
      styleBody: "",
      render: (item: any) => {
        return `
        <div class="flex">
      <div
        class="rounded-full px-2 py-0 ${
          item.status.toLowerCase() === "active"
            ? "bg-green-lp-300"
            : "bg-red-300"
        } ${
          item.status.toLowerCase() === "active"
            ? "text-green-lp-200"
            : "text-red-lp-200"
        }"
      ><span class="capitalize">${item.status.toLowerCase()}</span></div></div>`;
      }
    }
  ];

  created() {
    VendorController.initRequest();
    this.firstPage();
    VendorController.setStatus("");
  }

  getListVendor() {
    return VendorController.fetchVendorList();
  }

  get isFirstRequest() {
    return VendorController.firstRequest;
  }

  firstPage() {
    return VendorController.setFirstPage();
  }

  get vendors() {
    return VendorController.vendorData.data;
  }

  get isLoading() {
    return VendorController.isLoading;
  }

  get pagination() {
    return VendorController.page;
  }

  get limit() {
    return VendorController.limit;
  }

  nextPage() {
    VendorController.setNextPage();
  }

  prevPage() {
    VendorController.setPrevPage();
  }

  toPage = debounce(value => {
    VendorController.setPageAction(value);
  }, 500);

  onOpenStatusSelect() {
    this.statusSelect = true;
  }

  onCloseStatusSelect() {
    this.statusSelect = false;
  }

  get status() {
    return VendorController.status;
  }

  onSelectStatus(name: string, value: string) {
    VendorController.selectStatusAction(value);
    this.onCloseStatusSelect();
    this.statusName = name;
    this.firstPage();
  }

  get searchValue() {
    return VendorController.search;
  }

  onSearch(value: string) {
    VendorController.searchAction(value);
    this.firstPage();
    this.search = value;
  }

  clearSearch() {
    VendorController.clear();
    this.search = "";
    this.firstPage();
  }

  onClickRow(item: any) {
    this.$router.push(`/network/vendor/${item.id}`);
  }

  get isError() {
    return VendorController.isError;
  }

  get errorCause() {
    return VendorController.errorCause;
  }
}
