
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import { replaceWhiteSpaces } from "@/app/infrastructures/misc/Utils";
import Accordion from "@/app/ui/components/accordion/index.vue";

@Options({
  components: {
    Accordion
  },
  props: {
    columns: {
      default: [],
      type: Array
    },
    columnsCollipsible: {
      default: [],
      type: Array
    },
    data: {
      default: [],
      type: Array
    },
    onClick: {
      default: Function as PropType<() => void>,
      type: Function
    },
    isDetailAble: {
      type: Boolean,
      default: false
    }
  }
})
export default class Table extends Vue {
  replaceWhiteSpaces = replaceWhiteSpaces;
}
